import { useState } from 'react'

const useDeviceImage = () => {
  const [loading, setLoading] = useState(true)

  const onLoad = () => {
    setLoading(false)
  }

  const onError = () => {
    setLoading(true) // set src to default image
  }

  return {
    loading,
    onError,
    onLoad
  }
}

export default useDeviceImage
