import React from 'react'
import { RootComponent } from '../src'
import { ShellProps } from '../src/types/shell'
import { ThemeProvider } from '@veneer/theme'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import resources from '../src/assets/locale'
import { DirectionProvider } from '@veneer/core/dist/scripts/direction'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const theme = props?.themeProvider || {}
  props.localeInfo = v1?.localization

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <DirectionProvider direction={theme.dir}>
      <ThemeProvider {...theme}>
        <RootProvider
          localization={v1.localization}
          resources={resources}
          MethoneWinClient={props.MethoneWinClient}
          useModal={props.useModal}
          formatAutoId={props?.formatAutoId}
          authProvider={props.authProvider}
          subscriptionInfo={props.subscriptionInfo}
          dir={theme.dir}
          openDialog={props?.openDialog}
          closeDialog={props?.closeDialog}
          optinProvider={props.optinProvider}
          updateDeviceListBySN={props.updateDeviceListBySN}
          setDeviceDetails={props.setDeviceDetails}
          environment={props.environment}
          onComplete={props.onComplete}
        >
          <RootComponent {...props} />
        </RootProvider>
      </ThemeProvider>
    </DirectionProvider>
  )
}
