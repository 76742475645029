import { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { LocalizationType } from '../types/localization'

interface IMethoneClient {
  editDeviceNickName: (deviceID, updatedString) => Promise<any>
}

interface IContext {
  MethoneWinClient?: IMethoneClient
  localization?: LocalizationType
}

const useMethoneClient = () => {
  const { MethoneWinClient } = useContext<IContext>(RootContext)

  return { MethoneWinClient }
}

export default useMethoneClient
