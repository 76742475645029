/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useState } from 'react'
import useWarranty from '../../hooks/useWarranty'
import useContextAPI from '../../hooks/useContextAPI'
import { Skeleton } from '@jarvis/react-hpx-support-shared/dist/components/Skelton/Skeleton'
import useDeviceImage from '../../hooks/useDeviceImage'
import IconBattery50 from '@veneer/core/dist/esm/scripts/icons/icon_battery_50'
import IconBluetooth from '@veneer/core/dist/esm/scripts/icons/icon_bluetooth'
import IconShieldMarkCheck from '@veneer/core/dist/esm/scripts/icons/icon_shield_checkmark'

import {
  Container,
  Section,
  DefaultProductImage,
  Image,
  Column,
  ProductImage,
  FullRow,
  ProductNickName,
  ProductName,
  WarrantyStatusIcon
} from './styles'

const RootComponent = ({
  device,
  getWarrantyInfo,
  updateScreen,
  deviceScreenName,
  Skelton
}) => {
  const { isLoading } = useWarranty(device)
  const { onLoad, onError, loading } = useDeviceImage()
  const [edit] = useState(false)
  const {
    environment: { isMobile },
    formatAutoId
  } = useContextAPI()
  const ProductIconLazy = React.lazy(
    () => import('@jarvis/react-hpx-support-shared/dist/components/ProductIcon')
  )

  const batteryIcon = (
    <IconBattery50 size={20} color="colorBlack6"></IconBattery50>
  )
  const blueTooth = (
    <IconBluetooth size={20} color="colorBlack6"></IconBluetooth>
  )

  const shieldMarkCheck = (
    <IconShieldMarkCheck size={20} color="colorBlack6"></IconShieldMarkCheck>
  )

  const ProductIcon = ({ size, color, type }) => {
    return (
      <Suspense fallback={<Skeleton height={size} width={size} />}>
        <ProductIconLazy size={size} color={color} type={type} />
      </Suspense>
    )
  }
  if (!device && Skelton) {
    return <Skelton></Skelton>
  }
  return (
    <>
      {device && !isLoading && (
        <Container>
          <Section>
            <Column flex={1} alignItems={'center'} justifyContent={'flex-end'}>
              {!device && (
                <DefaultProductImage className="autoWidth">
                  <Skelton count={1} width="92px" height="92px" />
                </DefaultProductImage>
              )}
              {(loading || !device?.ImageLink) && (
                <DefaultProductImage className="autoWidth">
                  <ProductIcon
                    size={isMobile ? 144 : 120}
                    type={device?.ProductType || 2}
                    color="colorForegroundLight"
                  ></ProductIcon>
                </DefaultProductImage>
              )}
              <ProductImage
                className="autoWidth"
                onLoad={onLoad}
                onError={onError}
                style={{
                  display: loading || !device?.ImageLink ? 'none' : ''
                }}
              >
                <Image src={device?.ImageLink}></Image>
              </ProductImage>
            </Column>
            <Column flex={2}>
              <FullRow marginTop={20}>
                <Column alignItems="center">
                  <>
                    <ProductNickName
                      className={isMobile ? 'title-small' : 'subtitle-large'}
                      id={formatAutoId('nick-name')}
                    >
                      {device?.NickName}
                    </ProductNickName>
                  </>
                </Column>
              </FullRow>
              <FullRow justifyContent={'center'}>
                <ProductName
                  EditButton={edit}
                  className={'label-small'}
                  id={formatAutoId('product-name')}
                >
                  {device.ProductName}
                </ProductName>
              </FullRow>
            </Column>
            <Column>
              <FullRow
                width={'40%'}
                justifyContent={'space-evenly'}
                marginTop={20}
              >
                <WarrantyStatusIcon>{batteryIcon}</WarrantyStatusIcon>
                <WarrantyStatusIcon>{blueTooth}</WarrantyStatusIcon>
                <WarrantyStatusIcon>{shieldMarkCheck}</WarrantyStatusIcon>
              </FullRow>
            </Column>
          </Section>
        </Container>
      )}
    </>
  )
}
export default RootComponent
