import React, { useState } from 'react'
import {
  FullRow,
  Form,
  NickNameTextbox,
  NickNameButton
} from '../DeviceDetails/styles'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import useContextAPI from '../../hooks/useContextAPI'
import useMethoneClient from '../../API/methone'

const EditNickName = ({ setEdit, device }) => {
  const [saveBtnA, setSaveBtn] = useState<boolean>(true)
  const [nickNameError, setNickNameError] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const { MethoneWinClient } = useMethoneClient()
  const { updateDeviceListBySN } = useContextAPI()
  const { formatAutoId } = useContextAPI()
  function isValid(value) {
    const regex = new RegExp('[\\[<>%{}|^\\]\\\\]')
    if (
      value != null &&
      (typeof value === 'string' ? value?.trim().length > 0 : value.length > 0)
    ) {
      if (regex.test(value) === false) return true
      else return false
    } else return false
  }
  const updateNickname = async (deviceID, updatedString) => {
    try {
      const update = await MethoneWinClient?.editDeviceNickName(
        deviceID,
        updatedString
      )
      updateDeviceListBySN({
        NickName: updatedString,
        SerialNumber: device?.SerialNumber
      })
      return update
    } catch (e) {
      console.log(e)
    }
  }
  const submitHandler = async (event) => {
    event.preventDefault()
    const nicknameEntered = event?.target[0]?.value
    if (isValid(nicknameEntered)) {
      setUpdate(true)
      const edited = await updateNickname(
        device?.DeviceId,
        nicknameEntered.trim()
      )
      if (edited) {
        setEdit(false)
      }
      setUpdate(false)
    } else {
      setNickNameError(true)
    }
  }
  const handleChange = (event) => {
    console.log(isValid(event))
    if (isValid(event) === false) {
      setSaveBtn(true)
      setNickNameError(true)
    } else {
      setNickNameError(false)
      setSaveBtn(false)
    }
    if (device?.NickName === event) setSaveBtn(true)
  }
  const { t } = useI18n()
  return (
    <>
      <FullRow marginTop="4">
        <Form onSubmit={submitHandler}>
          <FullRow>
            <NickNameTextbox
              id={formatAutoId('product-nickname-edit-textbox')}
              tabIndex={-1}
              className="label-normal"
              defaultValue={device?.NickName}
              placeholder={t(
                'common.enterNicknamePlaceholder',
                'Enter Nickname'
              )}
              onChange={(e) => handleChange(e)}
              required
              error={nickNameError}
              helperText={
                nickNameError
                  ? t('common.DeviceNickname_Invalid', 'Nickname is invalid')
                  : ''
              }
              maxLength="30"
            />
          </FullRow>
          <FullRow marginTop="12" justifyContent="space-between">
            <NickNameButton
              id={formatAutoId('product-nickname-cancel-button')}
              tabIndex={-1}
              type="button"
              onClick={() => setEdit(false)}
              className="caption"
              appearance="secondary"
              small
            >
              <span className="caption">{t('common.cancel', 'Cancel')}</span>
            </NickNameButton>
            <NickNameButton
              id={formatAutoId('product-nickname-submit-button')}
              tabIndex={-1}
              type="submit"
              disabled={saveBtnA}
              className="caption"
              loading={update}
              small
            >
              <span className="caption">{t('common.save', 'Save')}</span>
            </NickNameButton>
          </FullRow>
        </Form>
      </FullRow>
    </>
  )
}

export default EditNickName
