import {
  getDeviceImageWin,
  DeviceInfoRequest
} from '@jarvis/web-support-commons'

export async function getdeviceImage(requestInfo): Promise<string | undefined> {
  let deviceImage = ``
  const requestParams: DeviceInfoRequest = {
    apiKey: requestInfo?.apiKey,
    basePath: requestInfo?.basePath,
    productNumber: requestInfo?.productNumber
  }
  try {
    const resultImage = await getDeviceImageWin(
      requestInfo?.serialNumber,
      requestParams
    )
    if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(resultImage))
      deviceImage = resultImage
  } catch (e) {
    if (typeof e === 'string') {
      console.error(e)
    } else if (e instanceof Error) {
      console.error(e)
    }
  }
  return deviceImage
}

export async function getWarrantyStatusTxt(
  warrantyState,
  t,
  localeInfo
): Promise<string> {
  let warrantyText = ``
  let warrantyEndDate = ``
  const Userlocale = localeInfo
  if (warrantyState?.status) {
    if (warrantyState?.warrantyEndDate) {
      const dateWithoutz = warrantyState?.warrantyEndDate?.replace('Z', '')
      const endDate = new Date(dateWithoutz)
      warrantyEndDate = Intl.DateTimeFormat(Userlocale).format(endDate)
    }
    switch (warrantyState.status) {
      case 'Active':
        warrantyText = `${t('common.expires')} ${warrantyEndDate}`
        break
      case 'Expired':
        warrantyText = `${t('common.expired')} ${warrantyEndDate}`
        break
      case 'Unknown':
        warrantyText = `${t('common.unknown')}`
        break
      case 'Pending':
        warrantyText = `${t('common.pending')}`
        break
      case 'OptinRequired':
        warrantyText = `${t('common.getDetails')}`
        break
      default:
        warrantyText = `${t('common.unknown')}`
    }
  }
  return warrantyText
}

export async function getSubscriptionStatusTxt(
  subscriptionState,
  t,
  localeInfo
): Promise<string> {
  let warrantyText = ``
  let warrantyEndDate = ``
  const Userlocale = localeInfo
  if (subscriptionState?.status) {
    if (subscriptionState?.warrantyEndDate) {
      const dateWithoutz = subscriptionState?.warrantyEndDate?.replace('Z', '')
      const endDate = new Date(dateWithoutz)
      warrantyEndDate = Intl.DateTimeFormat(Userlocale).format(endDate)
    }
    switch (subscriptionState.status) {
      case 'Active':
        warrantyText = `${t('common.expires')} ${warrantyEndDate}`
        break
      case 'Expired':
        warrantyText = `${t('common.expired')} ${warrantyEndDate}`
        break
      case 'Unknown':
        warrantyText = `${t('common.unknown')}`
        break
      case 'Pending':
        warrantyText = `${t('common.pending')}`
        break
      case 'OptinRequired':
        warrantyText = `${t('common.getDetails')}`
        break
      default:
        warrantyText = `${t('common.unknown')}`
    }
  }
  return warrantyText
}

export function formatUTCDateTolocale(utcdate, locale) {
  //const dateWithoutz = utcdate.replace('Z', '')
  const nDate = new Date(utcdate)
  const formattedDate = Intl.DateTimeFormat(locale).format(nDate)
  return formattedDate
}
