import { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { CoptorAuth } from '@jarvis/web-support-commons/dist/methone/authorization'
import { LocalizationType } from '../types/localization'

interface IAuthProvider {
  setAuthToken: () => string
  checkIsLoggedIn: () => boolean
  checkAuth: () => boolean
  setIsSignedIn: (isSignedIn: boolean) => void
  isSignedIn: boolean
  auth: CoptorAuth | string
}
interface IEnvironment {
  platform: string
  isNative: boolean
  isMobile: boolean
}
interface IOptinProvider {
  optin: boolean
  setOptin: (val: boolean) => void
  optinConfirm: (param: any) => boolean
}

interface IContext {
  authProvider?: IAuthProvider
  dir?: string
  localization?: LocalizationType
  optinProvider?: IOptinProvider
  updateDeviceListBySN?: (param: any) => void
  environment?: IEnvironment
  formatAutoId?: (val) => string
  onComplete?: () => void
}
const useContextAPI = () => {
  const {
    authProvider,
    dir,
    localization,
    optinProvider,
    environment,
    updateDeviceListBySN,
    formatAutoId,
    onComplete
  } = useContext<IContext>(RootContext)
  return {
    authProvider,
    dir,
    localization,
    optinProvider,
    environment,
    updateDeviceListBySN,
    formatAutoId,
    onComplete
  }
}

export default useContextAPI
