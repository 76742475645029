import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { ThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import DeviceDetails from './DeviceDetails'
import { LocalizationType } from '../types/localization'
import Device from '../mobile/Device'
interface IContext {
  dir?: string
  environment?: any
  localization?: LocalizationType
}

const RootComponent = (props) => {
  const theme = useTheme()
  const { environment, dir } = useContext<IContext>(RootContext)

  return (
    <ThemeProvider theme={{ ...theme, ...environment, dir }}>
      {environment?.isMobile ? (
        <Device {...props}></Device>
      ) : (
        <DeviceDetails {...props}></DeviceDetails>
      )}
    </ThemeProvider>
  )
}

export default RootComponent
