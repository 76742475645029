/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { WarrantyCache, SubscriptionInfo } from '@jarvis/web-support-commons'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { getWarrantyStatusTxt } from '../utils/utils'
import useContextAPI from './useContextAPI'

const useWarranty = (device) => {
  const { t } = useI18n()
  const { localization, onComplete } = useContextAPI()
  const isLoading = device?.loading
  const [warrantyText, setwarrantyText] = useState('')
  const warrantyState: WarrantyCache = device?.warrantyInfo || {
    status: '',
    statusCode: 0,
    userCheckDate: '',
    warrantyStartDate: '',
    warrantyEndDate: '',
    carepack: false
  }
  const subscriptionState: SubscriptionInfo = device?.subscriptionInfo || {
    status: '',
    userCheckDate: '',
    supportCode: '',
    subscriptionStatus: '',
    subscriptionId: '',
    subscriptionStartDate: ''
  }

  useEffect(() => {
    onComplete?.()
  }, [])

  useEffect(() => {
    ;(async () => {
      //set warranty text
      const warrantyTxt = await getWarrantyStatusTxt(
        device?.warrantyInfo,
        t,
        localization?.locale
      )
      setwarrantyText(warrantyTxt)
    })()
  }, [device?.warrantyInfo, device?.loading])

  return {
    warrantyText,
    warrantyState,
    subscriptionState,
    isLoading
  }
}
export default useWarranty
