import styled, { css } from 'styled-components'
import Token from '@veneer/tokens'
import HPXTheme, { right, left } from '../../styles/global'
import text_box from '@veneer/core/dist/esm/scripts/text_box'
import button from '@veneer/core/dist/esm/scripts/button'
// You can use variables from veneer tokens and do something like the below

const flexRow = css`
  display: flex;
  flex-direction: row;
`
const flexColumn = css`
  display: flex;
  flex-direction: column;
`
export const Container = styled.div`
  text-align: center;
  min-height: auto;
  ${flexColumn};
  margin-bottom: ${Token.space2};
  .icon-color {
    color: ${({ theme }) => theme.colorForeground};
  }
  background: ${({ theme }) => theme.colorBackgroundContainer};
  background-color: ${({ theme }) => theme.colorBackgroundContainer};
  margin-top: -5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .no-wrap {
    white-space: nowrap;
  }
`
export const Section = styled.div`
  ${flexRow};
  padding: ${Token.space3} 0px ${Token.space3} 0px;
  flex-direction: column;
  padding: calc(${Token.space8} - ${Token.space2});
`
export const SubSection = styled.div`
  ${flexColumn};
  width: 100%;
  padding-top: ${Token.space3};
  ${HPXTheme.mediaQueries.S} {
    padding-top: ${Token.space4};
  }
`

export const Row = styled.div`
  ${flexRow};
  margin-${right}: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  margin-${left}: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  align-items: ${(props) =>
    props.alignItems !== undefined ? props.alignItems : 'flex-start'};
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); //repeat(auto-fit, minmax(1px, auto));
  ${HPXTheme.mediaQueries.S} {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 20px;
`

export const FullRow = styled.div`
  ${flexRow};
  flex-direction: row;
  width: ${(props) => (props.width ? props.width : '100%')};
  margin-${right}: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  margin-${left}: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  justify-content: ${(props) =>
    props.justifyContent !== undefined ? props.justifyContent : 'flex-start'};
`
export const Column = styled.div`
  ${flexColumn};
  flex: ${(props) => (props.flex !== undefined ? props.flex : 1)};
  align-items: ${(props) =>
    props.alignItems !== undefined ? props.alignItems : 'flex-start'};
  justify-content: ${(props) =>
    props.justifyContent !== undefined ? props.justifyContent : 'flex-start'};
  padding: ${(props) =>
    props.padding !== undefined ? `0px ${props.padding}px` : '0px'};
  max-width: ${(props) =>
    props.maxWidth !== undefined ? `${props.maxWidth}px` : 'unset'};
  align-items: center !important;
  justify-content: flex-end !important;

  &.m-8 {
    margin-top: ${Token.space2};
    margin-bottom: ${Token.space2};
  }
`
export const ProductName = styled.h4`
  margin-top: ${Token.space1};
  margin-bottom: ${Token.space2};
  color: ${(props) =>
    props.EditButton
      ? ({ theme }) => {
          return theme.colorForeground
        }
      : ({ theme }) => {
          return theme.colorForegroundLight
        }};
  text-align: ${left};
`
export const ProductNickName = styled.h4`
  text-align: ${left};
  color: ${({ theme }) => theme.colorForeground};
`
export const Text = styled.span`
  color: ${({ theme }) => theme.colorForegroundLight};
  text-align: ${left};
  margin-${right}: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  margin-${left}: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
`
export const TextValue = styled.span`
  color: ${({ theme }) => theme.colorForegroundMedium};
  text-align: ${left};
  margin-${right}: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  margin-${left}: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  ${(props) =>
    props.copy &&
    css`
      -webkit-user-select: text !important;
      -ms-user-select: text !important;
      user-select: text !important;
    `}
`
export const DataLink = styled.a`
  color: ${Token.colorHpBlue6};
  text-align: ${left};
  margin-${right}: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  margin-${left}: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  cursor: pointer;
`

export const Image = styled.img`
  max-width: 144px;
  margin-top: ${Token.space2};
  ${HPXTheme.mediaQueries.L} {
    max-width: 144px;
  }
  ${HPXTheme.mediaQueries.M} {
    max-width: 125px;
  }
  ${HPXTheme.mediaQueries.S} {
    max-width: 158px;
  }
  ${({ theme }) =>
    theme.isMobile &&
    css`
      max-width: 144px !important;
      padding-bottom: 0px !important;
    `}
`
export const HPImage = styled.img`
  width: 18.33px;
  height: 18.33px;
  margin-${right}: ${Token.space2};
`
export const ImageLoader = styled.div`
  padding: ${Token.space5};
  margin-${right}: ${Token.lineHeight12};
  margin-top: ${Token.space5};
`
export const DefaultProductImage = styled.div`
  width: 144px;
  margin: -20px 0px;
`
export const ProductImage = styled.div`
  ${flexRow};
`
export const WarrantyStatusIcon = styled.div`
  display: flex;
  padding-${right}: 5px;
`
export const WarrantyOptinContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const EditButton = styled.a`
text-align: ${left};
color: ${({ theme }) => theme?.colorPrimaryLight};
margin-${right}: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
margin-${left}: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
cursor: pointer;
white-space: nowrap;
`
export const Form = styled.form`
  margin-bottom: ${Token.space8};
`
export const NickNameTextbox = styled(text_box)`
  width: 300px !important;
  .text {
    color: ${({ theme }) => theme?.colorNegative} !important;
  }
`
export const NickNameButton = styled(button)`
  width: 144px !important;
`
export const NickNameErrorText = styled.span`
  color: ${Token.colorRed6};
  margin: ${Token.space1} ${Token.space1};
`
