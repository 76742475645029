/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useState, useEffect } from 'react'
import IconCheckmarkCircle from '@veneer/core/dist/esm/scripts/icons/icon_checkmark_circle'
import IconXCircle from '@veneer/core/dist/esm/scripts/icons/icon_x_circle'
import useContextAPI from '../../hooks/useContextAPI'
import useWarranty from '../../hooks/useWarranty'
import useDeviceImage from '../../hooks/useDeviceImage'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import EditNickName from '../EditNickName'
import { formatUTCDateTolocale } from '../../utils/utils'
import {
  Column,
  Container,
  ProductName,
  FullRow,
  Text,
  TextValue,
  DataLink,
  Image,
  Section,
  SubSection,
  DefaultProductImage,
  WarrantyStatusIcon,
  ProductImage,
  ProductNickName,
  EditButton,
  Grid,
  HPAllInPlanLogo
} from './styles'
import { LocalizationType } from '../../types/localization'
import { Skeleton } from '@jarvis/react-hpx-support-shared/dist/components/Skelton/Skeleton'
import useOutsideClick from '@jarvis/react-hpx-support-shared/dist/hooks/useOutsideClick'

const ProductIconLazy = React.lazy(
  () => import('@jarvis/react-hpx-support-shared/dist/components/ProductIcon')
)

const ProductIcon = ({ size, color, type }) => {
  return (
    <Suspense fallback={<Skeleton height={size} width={size} />}>
      <ProductIconLazy size={size} color={color} type={type} />
    </Suspense>
  )
}
interface IModal {
  modal: unknown
  openModal: (modal) => void
  setResize: (resize: boolean) => void
  setOpen: (isOpen: boolean) => void
}
interface IContext {
  useModal?: () => IModal
  formatAutoId?: (val: string) => string
  localization?: LocalizationType
}

const RootComponent = ({
  device,
  getWarrantyInfo,
  updateScreen,
  deviceScreenName,
  Skelton
}) => {
  const { t } = useI18n()
  const {
    environment: { isMobile },
    authProvider,
    optinProvider,
    localization
  } = useContextAPI()
  const { isSignedIn, setAuthToken } = authProvider
  const { optin, optinConfirm } = optinProvider || {}
  const { warrantyInfo, subscriptionInfo } = device || {}
  const deviceSubscriptional =
    device?.IsSubscriptionDevice == 'false'
      ? false
      : device?.IsSubscriptionDevice
  const { isLoading, warrantyText } = useWarranty(device)
  const { onLoad, onError, loading } = useDeviceImage()
  const { formatAutoId } = useContext<IContext>(RootContext)
  const [edit, setEdit] = useState(false)

  const handler = (event) => {
    if (event?.which !== 3 && !event.target.getAttribute('data-copy'))
      window?.getSelection()?.removeAllRanges()
  }

  useOutsideClick(handler)

  useEffect(() => {
    updateScreen(deviceScreenName)
  }, [])

  let warrantyIcon: unknown = ``
  if (warrantyInfo?.status) {
    if (warrantyInfo.status == 'Active') {
      warrantyIcon = (
        <IconCheckmarkCircle
          size={14}
          color="colorGreen6"
        ></IconCheckmarkCircle>
      )
    } else if (warrantyInfo.status == 'Expired') {
      warrantyIcon = <IconXCircle size={14} color="colorRed6"></IconXCircle>
    }
  }

  const setConsentClick = async () => {
    if (!deviceSubscriptional) {
      getWarrantyInfo(
        device?.SerialNumber,
        device?.ProductNumber,
        device?.DeviceInfo?.locale?.language,
        device?.DeviceInfo?.locale?.country,
        true
      )
    }
  }
  const getDetailAction = () => {
    optinConfirm({
      okCallback: () => setConsentClick(),
      device
    })
  }

  if (!device && Skelton) {
    return <Skelton></Skelton>
  }

  return (
    <>
      {device && (
        <Container>
          <Section>
            <Column flex={2} maxWidth={720}>
              <FullRow>
                <Column alignItems="flex-start">
                  <FullRow>
                    {edit === false && (
                      <>
                        <ProductNickName
                          copy
                          className={
                            isMobile ? 'title-small' : 'subtitle-large'
                          }
                          id={formatAutoId('nick-name')}
                          data-copy="true"
                        >
                          {device?.NickName}
                        </ProductNickName>
                        {isSignedIn &&
                          device?.DeviceId !== 0 &&
                          device?.DeviceId !== undefined && (
                            <EditButton
                              className="link"
                              marginLeft="4"
                              tabIndex={-1}
                              id={formatAutoId('product-nickname-editbutton')}
                              onClick={(e) => {
                                e.preventDefault()
                                setEdit(!edit)
                              }}
                            >
                              {t('common.edit', 'Edit')}
                            </EditButton>
                          )}
                      </>
                    )}
                  </FullRow>
                  {edit && <EditNickName setEdit={setEdit} device={device} />}
                  <FullRow>
                    {isMobile ? (
                      <ProductName
                        EditButton={edit}
                        className={'label-small'}
                        id={formatAutoId('product-name')}
                      >
                        {device.ProductName}
                      </ProductName>
                    ) : (
                      <ProductName
                        copy
                        EditButton={edit}
                        className={edit ? 'subtitle-large' : 'caption'}
                        id={formatAutoId('product-name')}
                        data-copy="true"
                      >
                        {device.ProductName}
                      </ProductName>
                    )}
                  </FullRow>
                  <SubSection>
                    <Grid isMobile={isMobile}>
                      {device?.ProductType === 1 && deviceSubscriptional && (
                        <Column className={isMobile && 'm-8'}>
                          <FullRow display="flex">
                            <TextValue
                              className="subtitle no-wrap"
                              marginRight="20"
                              marginTop="0"
                              tabIndex={-1}
                              id={formatAutoId('hp-all-in-plan')}
                              role="text"
                            >
                              <HPAllInPlanLogo>
                                {`HP All-In Plan`}
                              </HPAllInPlanLogo>
                            </TextValue>
                          </FullRow>
                        </Column>
                      )}
                      <Column className={isMobile && 'm-8'}>
                        <FullRow>
                          <Text
                            className="caption-small no-wrap"
                            marginRight="20"
                            tabIndex={-1}
                            id={formatAutoId('product-number-label')}
                            role="text"
                          >
                            {t('common.productNumber')}
                          </Text>
                        </FullRow>
                        <FullRow display="flex" marginTop="6">
                          <TextValue
                            copy
                            className="caption-small no-wrap"
                            marginRight="20"
                            tabIndex={-1}
                            id={formatAutoId('product-number-value')}
                            role="text"
                            data-copy="true"
                          >
                            {device.ProductNumber}
                          </TextValue>
                        </FullRow>
                      </Column>
                      <Column className={isMobile && 'm-8'}>
                        <FullRow>
                          <Text
                            className="caption-small no-wrap"
                            marginRight="20"
                            tabIndex={-1}
                            id={formatAutoId('serial-number-label')}
                            role="text"
                          >
                            {t('common.serialNumber')}
                          </Text>
                        </FullRow>
                        <FullRow marginTop="6">
                          <TextValue
                            copy
                            className="caption-small no-wrap"
                            marginRight="20"
                            tabIndex={-1}
                            id={formatAutoId('serial-number-value')}
                            role="text"
                            data-copy="true"
                          >
                            {device.SerialNumber}
                          </TextValue>
                        </FullRow>
                      </Column>
                      {!deviceSubscriptional && (
                        <>
                          <Column className={isMobile && 'm-8'}>
                            <FullRow>
                              <Text
                                className="caption-small no-wrap"
                                marginRight="20"
                                tabIndex={-1}
                                id={formatAutoId('warranty-label')}
                                role="text"
                              >
                                {warrantyInfo?.carepack
                                  ? t('common.carePack')
                                  : t('common.warranty')}
                              </Text>
                            </FullRow>
                            <FullRow marginTop="6">
                              {isLoading && Skelton && (
                                <Skelton count={1} width="200px" height={15} />
                              )}
                              {!isLoading && warrantyIcon && optin && (
                                <WarrantyStatusIcon>
                                  {warrantyIcon}
                                </WarrantyStatusIcon>
                              )}
                              {!isLoading && optin && (
                                <TextValue
                                  copy
                                  className="caption-small no-wrap"
                                  tabIndex={-1}
                                  id={formatAutoId('warranty-status-value')}
                                  role="text"
                                  data-copy="true"
                                >
                                  {warrantyText}
                                </TextValue>
                              )}
                              {!isLoading && !optin && (
                                <>
                                  <DataLink
                                    href="#"
                                    className="caption-small no-wrap"
                                    tabIndex={0}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      getDetailAction()
                                    }}
                                    id={formatAutoId(
                                      'warranty-status-getDetail-value'
                                    )}
                                    role="link"
                                  >
                                    {t('common.getDetails')}
                                  </DataLink>
                                </>
                              )}
                            </FullRow>
                          </Column>
                          <Column></Column>
                        </>
                      )}
                      {deviceSubscriptional && !isSignedIn && (
                        <>
                          {' '}
                          <Column className={isMobile && 'm-8'}>
                            <FullRow>
                              <Text
                                className="caption-small no-wrap"
                                marginRight="20"
                                tabIndex={-1}
                                id={formatAutoId('subscription-label')}
                                role="text"
                              >
                                {t('common.GLB_SubscriptionLbl', 'Plan')}
                              </Text>
                            </FullRow>
                            <FullRow marginTop="6">
                              <DataLink
                                href="#"
                                className="caption-small no-wrap"
                                tabIndex={0}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setAuthToken()
                                }}
                                id={formatAutoId('subscription-get-detail')}
                                role="link"
                              >
                                {t('common.getDetails')}
                              </DataLink>
                            </FullRow>
                          </Column>
                          <Column></Column>
                        </>
                      )}
                      {deviceSubscriptional &&
                        isSignedIn &&
                        subscriptionInfo?.subscriptionId && (
                          <>
                            <Column className={isMobile && 'm-8'}>
                              <FullRow>
                                <Text
                                  className="caption-small no-wrap"
                                  marginRight="20"
                                  tabIndex={-1}
                                  id={formatAutoId('subscription-id-label')}
                                  role="text"
                                >
                                  {t('common.GLB_SubscriptionId', 'Plan ID')}
                                </Text>
                              </FullRow>
                              <FullRow marginTop="6">
                                <TextValue
                                  copy
                                  className="caption-small no-wrap"
                                  marginRight="20"
                                  tabIndex={-1}
                                  id={formatAutoId('subscription-id-value')}
                                  role="text"
                                  data-copy="true"
                                >
                                  {subscriptionInfo.subscriptionId}
                                </TextValue>
                              </FullRow>
                            </Column>
                            <Column>
                              <FullRow>
                                <Text
                                  className="caption-small no-wrap"
                                  marginRight="20"
                                  tabIndex={-1}
                                  id={formatAutoId(
                                    'subscription-startdate-label'
                                  )}
                                  role="text"
                                >
                                  {t('common.startDate')}
                                </Text>
                              </FullRow>
                              <FullRow marginTop="6">
                                <TextValue
                                  copy
                                  className="caption-small no-wrap"
                                  marginRight="20"
                                  tabIndex={-1}
                                  id={formatAutoId(
                                    'subscription-startdate-value'
                                  )}
                                  role="text"
                                  data-copy="true"
                                >
                                  {formatUTCDateTolocale(
                                    subscriptionInfo.subscriptionStartDate,
                                    localization?.locale
                                  )}
                                </TextValue>
                              </FullRow>
                            </Column>
                          </>
                        )}
                      {deviceSubscriptional &&
                        isSignedIn &&
                        !subscriptionInfo?.subscriptionId && (
                          <>
                            <Column></Column>
                            <Column></Column>
                          </>
                        )}
                    </Grid>
                  </SubSection>
                </Column>
              </FullRow>
            </Column>
            <Column
              flex={1}
              alignItems={isMobile ? 'center' : 'flex-end'}
              justifyContent={isMobile ? 'flex-end' : 'flex-end'}
            >
              {!device && (
                <DefaultProductImage className="autoWidth">
                  <Skelton count={1} width="92px" height="92px" />
                </DefaultProductImage>
              )}
              {(loading || !device?.ImageLink) && (
                <DefaultProductImage className="autoWidth">
                  <ProductIcon
                    size={isMobile ? 144 : 120}
                    type={device?.ProductType || 2}
                    color="colorForegroundLight"
                  ></ProductIcon>
                </DefaultProductImage>
              )}
              <ProductImage
                className="autoWidth"
                onLoad={onLoad}
                onError={onError}
                style={{
                  display: loading || !device?.ImageLink ? 'none' : ''
                }}
              >
                <Image src={device?.ImageLink}></Image>
              </ProductImage>
            </Column>
          </Section>
        </Container>
      )}
    </>
  )
}
export default RootComponent
